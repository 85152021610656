{
  "name": "axis-securities-report-ms",
  "version": "1.0.12",
  "description": "",
  "scripts": {
    "lint": "tsc --noEmit && eslint src/* --quiet",
    "lint:fix": "eslint src/* --fix --quiet",
    "test": "echo \"Error: no test specified\" && exit 1",
    "analyze": "npm run clean-and-copy && parcel build public/index.ejs public/version.ejs --dist-dir build --reporter @parcel/reporter-bundle-analyzer",
    "start": "npm run clean-and-copy && parcel public/index.ejs --dist-dir build --open chrome",
    "build": "npm run clean-and-copy && parcel build public/index.ejs public/version.ejs --dist-dir build",
    "clean-and-copy": "rm -rf build .parcel-cache && npm run copy",
    "copy": "mkdir build && mkdir build/static && cp -r public/static/ build/static/ && cp public/robots.txt build/",
    "prepare": "husky"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "dependencies": {
    "@am92/react-design-system": "2.5.0",
    "@am92/web-http": "^2.0.25",
    "@parcel/service-worker": "2.9.3",
    "@reduxjs/toolkit": "^1.9.7",
    "bowser": "^2.11.0",
    "core-js": "^3.33.0",
    "cosmiconfig": "8.3.6",
    "date-fns": "^2.30.0",
    "file-saver": "^2.0.5",
    "jszip": "^3.10.1",
    "localforage": "^1.10.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-redux": "^8.1.3",
    "react-router-dom": "^6.16.0",
    "redux": "^4.2.1",
    "redux-persist": "^6.0.0"
  },
  "devDependencies": {
    "@parcel/config-default": "2.9.3",
    "@parcel/core": "2.9.3",
    "@parcel/optimizer-terser": "2.9.3",
    "@parcel/packager-raw-url": "2.9.3",
    "@parcel/packager-xml": "2.9.3",
    "@parcel/reporter-bundle-analyzer": "2.9.3",
    "@parcel/transformer-raw": "2.9.3",
    "@types/file-saver": "^2.0.7",
    "@types/lodash": "^4.14.202",
    "@types/node": "^20.8.2",
    "@types/react": "^18.2.25",
    "@types/react-dom": "^18.2.10",
    "assert": "^2.1.0",
    "buffer": "^6.0.3",
    "console-browserify": "^1.2.0",
    "custom-env": "^2.0.2",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsonc": "^2.13.0",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-react": "^7.33.2",
    "eslint-plugin-simple-import-sort": "^12.0.0",
    "eslint-plugin-unused-imports": "^3.1.0",
    "husky": "^9.0.11",
    "prettier": "^3.2.5",
    "typescript": "^5.3.3",
    "lodash": "^4.17.21",
    "parcel": "2.9.3",
    "parcel-config-pwa-manifest": "^0.1.6",
    "parcel-namer-rewrite": "2.0.0-rc.3",
    "parcel-transformer-ejs": "^1.0.1",
    "path-browserify": "^1.0.1",
    "process": "^0.11.10",
    "prop-types": "^15.8.1",
    "react-jsx": "^1.0.0",
    "util": "^0.12.3"
  },
  "parcel-namer-rewrite": {
    "profiles": {
      "development": {
        "disable": true
      },
      "production": {
        "chain": "@parcel/namer-default",
        "rules": {
          "(.*).css$": "styles/$1{.hash}.css",
          "service-worker.js": "service-worker.js",
          "public(.*).webmanifest": "manifest{.hash}.webmanifest",
          "public(.*).xml": "browserconfig{.hash}.xml",
          "public(.*).webp": "assets/favicons/favicon{.hash}.webp",
          "public(.*).png": "assets/favicons/favicon{.hash}.png",
          "(.*).js$": "scripts/$1{.hash}.js",
          "(.*).png$": "assets/images/$1{.hash}.png",
          "(.*).webp$": "assets/images/$1{.hash}.webp",
          "(.*).jpg$": "assets/images/$1{.hash}.jpg",
          "(.*).jpeg$": "assets/images/$1{.hash}.jpeg",
          "(.*).svg$": "assets/images/$1{.hash}.svg",
          "(.*).gif$": "assets/images/$1{.hash}.gif",
          "(.*).avif$": "assets/images/$1{.hash}.avif",
          "version.html": "version.json"
        }
      }
    }
  },
  "@parcel/resolver-default": {
    "packageExports": true
  }
}
